import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const CustomHelmet = ({ titleKey, descriptionKey }) => {
    const { t } = useTranslation();

    // Fetch translated title and description
    const title = t(titleKey);
    const description = t(descriptionKey);

    const canonicalUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <title>{title}</title>
            <meta name="description" content={description} />
            <link rel="canonical" href={canonicalUrl} />
            <link rel="alternate" href={canonicalUrl}  />
            {['en', 'es', 'fr', 'de', 'el', 'it', 'ja', 'sv'].map((lang) => (
                <link rel="alternate" hreflang={lang} href={canonicalUrl} key={lang} />
            ))}
        </Helmet>
    );
};

export default CustomHelmet;