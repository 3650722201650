import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import domainConfig from "../config";
import { Breadcrumb, Spinner } from 'react-bootstrap';
import ProductCard from "./ProductCard";
import ReactGA from "react-ga4";
import { useTranslation } from 'react-i18next';
import { getBrowserLanguage, changeLanguage } from './i18n';
import Testimonies from './Testimonies';
import { Helmet } from 'react-helmet';
import '../css/Home.css';
import adImage from '../asset/affiliate-image.png'


const Home = () => {
    const navigate = useNavigate();
    const { country } = useParams();
    const [pivotData, setPivotData] = useState([]);
    const [latestData, setLatestData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [countryInfo, setCountryInfo] = useState([]);
    const { t, i18n } = useTranslation(); 
    const browserLanguage = getBrowserLanguage();

    useEffect(() => {
        changeLanguage(browserLanguage);
    }, [browserLanguage]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `${country}` });
    }, [country]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                // Fetch latest records
                const latestRecordsResponse = await axios.get(`${domainConfig()}/latest-records/${country}`);
                const sortedProducts = latestRecordsResponse.data.products.sort((a, b) => b.price - a.price);
                setLatestData({ ...latestRecordsResponse.data, products: sortedProducts });

                // Fetch country info
                const countryResponse = await axios.get(`${domainConfig()}/country/all`);
                const matchedCountry = countryResponse.data.find(data => data.country === country);
                setCountryInfo(matchedCountry);

                // Fetch pivot data
                const pivotResponse = await axios.get(`${domainConfig()}/pivot/${country}`);
                if (pivotResponse.data && Array.isArray(pivotResponse.data)) {
                    setPivotData(pivotResponse.data);
                } else {
                    console.error('Unexpected data structure:', pivotResponse.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [country]);

    const calculateIndicator = (data) => {
        if (!Array.isArray(data)) {
            console.error('Data is not an array:', data);
            return { indicator: 'N/A', isLowest: false, difference: 0 };
        }
        const yValues = data.map(point => point.y);
        const latestValue = data.slice(-1)[0].y;
        const isLowest = yValues.every(value => value >= latestValue);
        return isLowest;
    };

    const transformedProducts = latestData && latestData.products ? latestData.products.map(product => ({
        ...product,
        data: [{ x: new Date(), y: product.price }]
    })) : [];

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <h1>Rendering now. Wait a few more seconds...</h1>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
    
    return(
        <div className="home-text-container">
            <Helmet>
                <title>{t('title')} - {`${countryInfo.local}`}</title>
                <meta name="description" content={t('content')} />
                <link rel="canonical" href={canonicalUrl} />
                <link rel="alternate" href={canonicalUrl} hreflang={countryInfo.language} />
            </Helmet>
            <div className="breadcrumb">
                <Breadcrumb>
                    <Breadcrumb.Item href="/">Top</Breadcrumb.Item>
                    <Breadcrumb.Item active>{`${country}`}{`${countryInfo.emoji}`}</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="home-container" fluid>
                <div className="centered-h1">
                    <h1 className="style-33aGo">{t('applePriceHistory')}</h1>
                    <p>Back Market {`${countryInfo.local}`} {`${countryInfo.emoji}`}</p>
                    <hr></hr>
                    <p>{t('intro')}</p>
                    <a href={countryInfo.affiliate} target="_blank" rel="noopener noreferrer"
                    style={{ display: 'flex', justifyContent: 'center'}}>
                        <img src={adImage} 
                            alt="Link to Amazon for more refurbished iPhones"
                            style={{ width: '50%', height: 'auto' }}
                           />
                    </a>
                    <hr></hr>
                    <p>{t('affiliateSupportRequest')}</p>
                    {countryInfo.affiliate && (
                    <p className="affiliate-text">
                        <a href={countryInfo.affiliate}>
                            {t('shopAmazon')}
                        </a>        
                    </p>
                )}
                </div>
                <hr></hr>
                <div className="sub-title">
                    <h3 className="sub-title">{t('bestSelling')}</h3>
                </div>
                {countryInfo.affiliate && (
                    <p className="affiliate-text">
                        <a href={countryInfo.affiliate}>
                            {t('checkMore')}
                        </a>        
                    </p>
                )}
                <div className="product-cards-container">
                    {transformedProducts.map((item, index) => {
                        const matchingRecord = pivotData.find(record => record.label === item.model);
                        const isLowest = matchingRecord ? calculateIndicator(matchingRecord.data) : false;
                        return (
                            <ProductCard 
                            className="product-card"
                            product={item}
                            navigate={navigate} 
                            selectedCountry={country}
                                isLowest={isLowest}
                                langCode={browserLanguage}
                                />
                            );
                        })}
                </div>
                {/* <div className="product-card-table-container">
                    <ProductCard langCode={browserLanguage} />
                </div> */}
                <Testimonies country={country} />
                <p>{t('affiliateSupportRequest')}</p>
                    {countryInfo.affiliate && (
                    <p className="affiliate-text">
                        <a href={countryInfo.affiliate}>
                            {t('shopAmazon')}
                        </a>        
                    </p>
                )}
            </div>
        </div>
    )
}

export default Home;
