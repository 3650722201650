import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import domainConfig from "../config";
import PriceChart from "./PriceChart";
import { Container, Row, Col, Badge, Breadcrumb } from 'react-bootstrap';
import ProductCard from "./ProductCard";
import ReactGA from "react-ga4";
import { getBrowserLanguage, changeLanguage } from './i18n';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import '../css/Model.css'

const Model = () => {
    const { country, model } = useParams();
    const [countryInfo, setCountryInfo] = useState([]);
    const [pivotRecords, setPivotRecords] = useState([]);
    const [latestRecord, setLatestRecord] = useState([]);
    const [latestAllRecords, setLatestAllRecords] = useState([]);
    const { t, i18n } = useTranslation();

    const browserLanguage = getBrowserLanguage();
    useEffect(() => {
        changeLanguage(browserLanguage);
    }, [browserLanguage]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: `${country}/${model}` });
    }, [country, model]);

    useEffect(() => {
        const fetchData = async () => {
            const countryResponse = await axios.get(`${domainConfig()}/country/all`);
            const matchedCountry = countryResponse.data.find(data => data.country === country);
            setCountryInfo(matchedCountry);

            const pivotResponse = await axios.get(`${domainConfig()}/pivot/${country}`);
            const records = pivotResponse.data.filter(record => record.label === model);
            setPivotRecords(records);

            const latestResponse = await axios.get(`${domainConfig()}/latest-records/${country}`);
            setLatestAllRecords(latestResponse);
            const record = latestResponse.data.products.filter(record => record.model === model);
            if (record.length !== 0) {
                setLatestRecord(record[0]);
            }
        };
        fetchData();
    }, [country, model]);

    const calculateIndicator = (data) => {
        const yValues = data.map(point => point.y);
        const className = yValues.reduce((a, b) => a + b, 0) / yValues.length;
        const latestValue = data.slice(-1)[0].y;
        const difference = (latestValue - className) / className * 100;
        const isLowest = yValues.every(value => value >= latestValue);

        let indicator = '☆☆☆'; // Default value
        if (difference < -20) {
            indicator = '★★★';
        } else if (difference < -10) {
            indicator = '★★☆';
        } else if (difference < 5) {
            indicator = '★☆☆';
        }

        return { indicator, isLowest, difference };
    };

    const recordsWithIndicators = pivotRecords.map(record => ({
        ...record,
        ...calculateIndicator(record.data)
    }));

    const calculateclassName = (records) => {
        const sum = records.reduce((acc, record) => acc + record.y, 0);
        const className = sum / records.length;
        return Math.round(className);
    };

    const avg = recordsWithIndicators[0] ? calculateclassName(recordsWithIndicators[0].data) : null;
    const minValue = Math.min(...recordsWithIndicators.flatMap(record => record.data.map(point => point.y)));
    const maxValue = Math.max(...recordsWithIndicators.flatMap(record => record.data.map(point => point.y)));
    const latestRecordUrl = latestRecord && latestRecord.url ? latestRecord.url : '';

    const classNameLine = {
        label: 'Average price',
        data: recordsWithIndicators.flatMap(record => record.data.map(point => ({ x: point.x, y: avg }))),
        fill: false,
        borderColor: 'rgba(0, 0, 255, 0.5)'
    };

    const allData = [...recordsWithIndicators, classNameLine];
    const isLowest = recordsWithIndicators.some(record => record.isLowest);
    const calculatePriceComparison = (latestPrice, classNamePrice) => {
        const difference = (latestPrice - classNamePrice) / classNamePrice * 100;
        return difference.toFixed(2); // Format to 2 decimal places
    };
    const [priceComparison, setPriceComparison] = useState(null);

    useEffect(() => {
        const comparison = calculatePriceComparison(latestRecord.price, avg);
        setPriceComparison(comparison);
    }, [latestRecord, avg]);

    let productCards = [];
    const products = latestAllRecords.data?.products;
    if (products) {
        const productsWithDifference = products.map(product => ({
            ...product,
            difference: Math.abs(product.price - latestRecord.price)
        }));
        const sortedProducts = productsWithDifference.sort((a, b) => a.difference - b.difference);
        const topProducts = sortedProducts.slice(0, 4);
        productCards = topProducts.map((product, index) => (
            <ProductCard key={index} product={product} langCode={browserLanguage} />
        ));
    } else {
        productCards = <div>Loading products...</div>;
    }

    const canonicalUrl = `${window.location.origin}${window.location.pathname}`;
   

    return (
        <div>
            <Helmet>
                <title>{model}, {t('title')} - {`${countryInfo.local}`}</title>
                <meta name="description" content={t('content')} />
                <link rel="canonical" href={canonicalUrl} />
                <link rel="alternate" href={canonicalUrl} hreflang={countryInfo.language} />
            </Helmet>
            <Breadcrumb className="breadcrumb">
                <Breadcrumb.Item href="/">{t('countrySelect')}</Breadcrumb.Item>
                <Breadcrumb.Item href={`/country/${country}`}>{`${country}${countryInfo.emoji}`}</Breadcrumb.Item>
                <Breadcrumb.Item active>{model}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="cmo-mod cmo-heading-generic">
                <div className="hd">
                    <h2 className="style-YWczO">{t('priceHistoryOf')}</h2>
                    <h1 className="style-YWczO">{t('used')} {model}</h1>
                    <p>{t('onBackMarket')} {country}</p>
                </div>
            </div>
            <hr></hr>
            <Container className="cmo-mod cmo-mp-image">
                <Row>
                    <Col md={3}>
                        <p title={`Price History of ${country}`} className="link" rel="nofollow">
                            <div className="model-thumbnail-small">
                                {model}
                            </div>
                        </p>
                    </Col>
                    <Col className="cmo-mod cmo-product-price-overview" md={9}>
                        {isLowest && latestRecord.price && <Badge bg="info" className="ml-2">{t('lowestIn2Weeks')}</Badge>}
                        {latestRecord.product_title && latestRecord.product_title.map((title, index) => (
                            <Badge key={index} bg="dark" className="ml-2 product-tag">{title}</Badge>
                        ))}
                        <Row className="section">
                            <Col md={6} className="item">
                                <div className="label">{t('currentPrice')}</div>
                                <div className="label current">
                                    {countryInfo && countryInfo.currency && latestRecord.price ? 
                                    `${countryInfo.currency}${latestRecord.price.toLocaleString()}` : t('soldOut')}
                                </div>
                            </Col>
                            <Col md={6} className="item">
                                <div className="label lowest">{t('lowestPrice')}</div>
                                <div>{countryInfo.currency}{minValue.toLocaleString()}</div>
                            </Col>
                            <Col md={6} className="item">
                                <div className="label highest">{t('highestPrice')}</div>
                                <div>{countryInfo.currency}{maxValue.toLocaleString()}</div>
                            </Col>
                            <Col md={6} className="item">
                                <div className="label average">{t('averagePrice')}</div>
                                <div>
                                    {countryInfo && countryInfo.currency && latestRecord.price ? 
                                    `${countryInfo.currency}${avg.toLocaleString()}` : t('soldOut')}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    {!isNaN(priceComparison) && 
                        <p className="price-comparison">
                            {t('currentPriceIs')}
                            <span className="price-sub-highlight">
                                {Math.abs(priceComparison)}% {priceComparison < 0 ? t('lower') : t('higher')}
                            </span>
                            {t('thanTheAveragePrice')}.
                        </p>
                    }
                </Row>
            </Container>
            <hr></hr>
            <div className="bd">
                <table className="label-medium">
                    <tbody>
                        <tr >
                            <td className="label style-AUawa"><b>{t('currentPrice')}</b></td>
                            <td className="value style-C7TMw">
                                <ul>
                                    <li>
                                        <div>
                                            <span className="price-final js-product-price style-1UWlW">
                                                {countryInfo && countryInfo.currency && latestRecord.price ? 
                                                `${countryInfo.currency}${latestRecord.price.toLocaleString()}` : t('soldOut')}
                                            </span>
                                            <span className="discount-percent js-product-discount-percentage">{priceComparison}% {priceComparison < 0 ? t('lower') : t('higher')} (vs {t('averagePrice')})</span>
                                        </div>
                                    </li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td className="label"><b>{t('shopNow')}</b></td>
                            <td className="value">
                                <ul>
                                    <li><span className="buy-button"><div title="Go to shop" onClick={() => window.open(`${countryInfo.domain}${latestRecordUrl}`, "_blank")} rel="noreferrer" role="button">{t('buyOnBackMarket')}</div></span></li>
                                </ul>
                                <ul>
                                {countryInfo.affiliate && (
                                    <li><span className="buy-button-amzn"><a title="Check similar products on Amazon" href={countryInfo.affiliate} rel="noreferrer" role="button">{t('checkMoreOnAmazon')}</a></span></li>
                                    )}
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div className="info">
                                    <small>
                                        {t('productPricesAndAvailability')}
                                    </small>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <h2 className="style-YWczO middle">{t('priceHistoryInTheLast2Weeks')}</h2>
                <div className="full-width-on-small">
                    <PriceChart className="price-chart" data={allData} chartTitle={`Price Chart ( ${countryInfo.currency} )`} />
                </div>
                <div className="cmo-mod cmo-heading-generic">
                    <p className="affiliate-support-request">{t('affiliateSupportRequest')}</p>
                        {countryInfo.affiliate && (
                        <p className="affiliate-text">
                            <a href={countryInfo.affiliate}>
                            {t('shopAmazon')}
                            </a>        
                        </p>
                        )}
                </div>
                <h2 className="style-YWczO middle">{t('otherModelsInTheSamePriceRange')}</h2>
                <div className="product-cards-container">
                    {productCards}
                </div>
            </div>
        </div>
    );
};

export default Model;
