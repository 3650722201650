// Footer.js
import React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import domainConfig from '../config';
import { Link } from 'react-router-dom';
import '../css/Footer.css'


const Footer = () => {

    const [countries, setCountries] = useState([]);
    useEffect(() => {
        axios.get(`${domainConfig()}/country/all`)
          .then(response => {
            setCountries(response.data);
          });
     }, []);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, [location]);
    
    return (
        <footer className="app-footer">
            <h2 className='style-YWczO middle'>
                List of Back Market countriy pages
            </h2>
            <div className="container">
                <div className="countries-list">
                    {countries.map((country, index) => (
                        <div key={index} className="country-item">
                            <Link to={`/country/${country.country}`}>
                                {country.country}{country.emoji}
                            </Link>
                        </div>
                    ))}
                </div>
                        <div className="social-links">
                            <a href="https://twitter.com/bkmkt_tracker?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @bkmkt_tracker and check latest refurbished iPhone prices</a>
                            <p>
                                This site contains Amazon affiliate links.
                            </p>
                            <span>&copy; {new Date().getFullYear()} Back Market Price Tracker. All rights reserved.</span>
                        </div>
            </div>
        </footer>
    );
};

export default Footer;