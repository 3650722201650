import React from 'react';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import domainConfig from '../config';
import '../css/TopDropsList.css';

const TopDropsList = ({ country, topDrops }) => {

  const [countryInfo, setCountryInfo] = useState([]);
  useEffect(() => {
    axios.get(`${domainConfig()}/country/all`)
      .then(response => {
        const filteredCountries = response.data.filter(countryObj => countryObj.country === country || countryObj.id === country);
        setCountryInfo(filteredCountries[0]);
      });
  }, [country]);


    return (
      <div className="top-drops-table">
        <h2>
            <Link to={`/country/${country}`}>
                {country} {countryInfo.emoji}
            </Link>
        </h2>
        <table>
          <thead>
            <tr>
              <th>Model</th>
              <th>Price Now</th>
              <th>Price Before</th>
              <th>Drop Rate</th>
            </tr>
          </thead>
          <tbody>
            {topDrops.map((drop, index) => (
                <tr key={index}>
                    <Link to={`/country/${country}/${drop.model}`}>
                        <td>{drop.model}</td>
                    </Link>
                        <td className="price">{countryInfo.currency}{parseInt(drop.latestPrice).toLocaleString()}</td>
                        <td>{countryInfo.currency}{parseInt(drop.previousPrice).toLocaleString()}</td>
                        <td className="drop-rate">{parseInt(drop.dropRate)}%</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  
  

export default TopDropsList;
