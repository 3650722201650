// TopForm.js
import React, { useState } from 'react';
import TopDropsList from './TopDropsList.js';
import '../css/TopForm.css'
import { useEffect } from 'react';
import axios from "axios";
import domainConfig from "../config";
import { Spinner } from 'react-bootstrap';

const DropDash = () => {

    const [isLoading, setIsLoading] = useState(true);

    const [pivotData, setPivotData] = useState('')
    useEffect(() => {
        axios.get(`${domainConfig()}/cheapest-pivot`)
            .then(response => {
                setPivotData(response.data);
                setIsLoading(false); // Set loading to false after data is loaded
            })
    }, []);


    return (
        <div className="container">
            {isLoading ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <h1>Rendering now. Wait a few more seconds...</h1>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    <h1>Today's best deals</h1>
                    <p>Click model names for details</p>
                    <div className="row">
                        {pivotData.length > 0 && pivotData.map((countryData, index) => (
                            <div className="col-md-4" key={index}>
                                <TopDropsList 
                                    country={countryData.country} 
                                    topDrops={countryData.topDrops} 
                                />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default DropDash;