import React from 'react';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from './i18n';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const switchLanguage = (event) => {
    const lng = event.target.value;
    changeLanguage(lng);
  };

  return (
    <select value={i18n.language} onChange={switchLanguage}>
      <option value="en">English    🇬🇧🇺🇸</option>
      <option value="fr">Français   🇫🇷</option>
      <option value="it">Italiano   🇮🇹</option>
      <option value="de">Deutsch    🇩🇪</option>
      <option value="nl">Dutch      🇳🇱</option>
      <option value="es">Español    🇪🇸</option>
      <option value="pt">Português  🇵🇹</option>
      <option value="el">Ελληνικά   🇬🇷</option>
      <option value="sk">Slovenčina 🇸🇰</option>
      <option value="sv">Svenska    🇸🇪</option>
      <option value="fi">Suomi      🇫🇮</option>
      <option value="ja">日本語      🇯🇵</option>
    </select>
  );
};

export default LanguageSwitcher;