import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../locales/en.json';
import frTranslation from '../locales/fr.json';
import deTranslation from '../locales/de.json';
import itTranslation from '../locales/it.json';
import jaTranslation from '../locales/ja.json';
import esTranslation from '../locales/es.json';
import svTranslation from '../locales/sv.json';
import elTranslation from '../locales/el.json';
import nlTranslation from '../locales/nl.json';
import ptTranslation from '../locales/pt.json';
import skTranslation from '../locales/sk.json';
import fiTranslation from '../locales/fi.json';


const getBrowserLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  return language.split('-')[0]; // Use 'en' instead of 'en-US'
}

const languageMapping = {
  France: "fr",
  Belgium: "fr",
  Austria: "de",
  Germany: "de",
  Netherlands: "nl",
  Spain: "es",
  Porugal: "pt",
  Italy: "it",
  Greece: "el",
  Finland: "fi",
  Sweden: "sv",
  Slovakia: "sk",
  Ireland: "en",
  UnitedKingdom: "en",
  UnitedStates: "en",
  Australia: "en",
  Japan: "ja",
};


// Function to initialize i18n with language from localStorage or fallback
const initI18nWithFallback = (fallbackLang = 'en') => {
  const savedLanguage = localStorage.getItem('language');


console.log('Saved language:', savedLanguage);
  const language = savedLanguage? savedLanguage : getBrowserLanguage();
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      de: {
        translation: deTranslation,
      },
      it: {
        translation: itTranslation,
      },
      ja: {
        translation: jaTranslation,
      },
      es: {
        translation: esTranslation,
      },
      sv: {
        translation: svTranslation,
      },
      el: {
        translation: elTranslation,
      },
      nl: {
        translation: nlTranslation,
      },
      pt: {
        translation: ptTranslation,
      },
      sk: {
        translation: skTranslation,
      },
      fi: {
        translation: fiTranslation,
      }
    },
    lng: language, // Set the initial language
    fallbackLng: fallbackLang,
    detection: {
      order: ['localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
      cache: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });
};

initI18nWithFallback();
  
  // Save the selected language to localStorage whenever it changes
  i18n.on('languageChanged', (lng) => {
    localStorage.setItem('language', lng);
  });

// Function to change the language dynamically
const changeLanguage = (language) => {
   i18n.changeLanguage(language);
 }

export { i18n, changeLanguage, getBrowserLanguage, languageMapping };

