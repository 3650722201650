import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import domainConfig from '../config';
import '../css/CountrySelect.css';


const CountrySelect = ({ onCountrySelect }) => {
    const navigate = useNavigate();

    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    useEffect(() => {
       axios.get(`${domainConfig()}/country/all`)
         .then(response => {
           setCountries(response.data);
         });
    }, []);
    const handleSelectChange = (event) => {
       setSelectedCountry(event.target.value);
      };
      const handleGoClick = () => {
        if (selectedCountry) {
          onCountrySelect(selectedCountry);
          navigate(`/country/${selectedCountry}`);
        }
    };

   
    return (
      <div className="select-and-button-container">
          <select className="form-select-country me-2 custom-dropdown" value={selectedCountry} onChange={handleSelectChange}>
              <option key="none" value="" hidden>Select your country</option>
              {countries.map((country, index) => (
                  <option key={index} value={country.country}>
                      {country.country} {country.emoji}
                  </option>
              ))}
          </select>
          <div className="button" onClick={handleGoClick}>Start Tracking Now</div>
      </div>
  );
};

export default CountrySelect;