import React from 'react';
import { Chart as ChartJS, Legend } from 'chart.js/auto'
import { Chart, Line }from 'react-chartjs-2'

import '../css/PriceChart.css'

ChartJS.register(Legend);


const formatDate = (date) => {
    const d = new Date(date);
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}`;
   };
   
const PriceChart = ({ data, chartTitle }) => {

    const options = {
            maintainAspectRatio: false,
            // aspectRatio: 1,
            responsive: true,
            plugins: {
                title: {
                    display: false,
                    // text: chartTitle,
                    align: "center",
                    padding: {
                    top: 5,
                    bottom: 10,
                    },
                    font: {
                        size: 14
                    }
                },
                legend: {
                    display: true,
                    position: 'right',
                    align: 'center',
                    labels: {
                        boxWidth: 20,
                        fontSize: 8,
                        padding: 10,
                    },
                },
            },
        }    
    
        const labels = Array.from(new Set(data.flatMap(dataset => dataset.data.map(d => `${formatDate(d.x)}`))))
        .sort((a, b) => a.localeCompare(b));

        const datasets = data.map(dataset => ({
            label: dataset.label,
            data: labels.map(label => dataset.data.find(d => `${formatDate(d.x)}` === label)?.y || null),
            fill: false,
            borderColor: dataset.borderColor
        }));

    return (
        <div>
            <Line data={{ labels, datasets }} options={options} className="custom-chart" />
        </div>
    )
};

export default PriceChart;