import React from 'react';
import { Navbar } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import CountrySelect from '../component/CountrySelect'; 
import LanguageSwitcher from './LanguageSwitcher';
import '../css/Header.css'

const Header = () => {
    const navigate = useNavigate();
    const handleBrandClick = () => {
        navigate('/'); // Navigate to the homepage
    };

    const handleCountrySelection = (country) => {
        navigate(`/country/${country}`);
    };

    return (
        <div className="navbar-header">
            <Navbar expand="lg">
                <Navbar.Brand className='navbar-brand' href="/" onClick={handleBrandClick}>Back Market Price Tracker</Navbar.Brand>
                <div className="navbar-select">
                    {/* <div>
                        <CountrySelect onCountrySelect={handleCountrySelection} />
                    </div> */}
                    <div className='language-switch'>
                       <LanguageSwitcher />
                    </div>
                </div>
            </Navbar>
        </div>
    );
};

export default Header;