// TopForm.js
import React from 'react';
import CountrySelect from './CountrySelect.js';
import '../css/TopForm.css'
import { useState, useEffect } from 'react';
import ReactGA from "react-ga4";
import axios from "axios";
import domainConfig from "../config";

const TopForm = () => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [pivotData, setPivotData] = useState([]);

    const handleCountrySelection = (country) => {
        setSelectedCountry(country);
    };

    useEffect(() => {
        axios.get(`${domainConfig()}/cheapest-pivot`)
          .then(response => {
            setPivotData(response.data);
          });
     }, []);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: "/window.location.pathname", title: "Home" });
    }, []);

    return (
        <header className="masthead">
            <div className="container position-relative">
                <div className="row justify-content-center">
                    <div className="col-xl-6">
                        <div className="text-center text-white">
                            <h1 className="catch">Get the Best Deals on 
                                <span className='catch-emp'> iPhones and Save Big!</span>
                            </h1>
                            <p className='top-desc'>Track iPhone prices on Back Market in real-time</p>
                            <div className='select-container'>
                                <CountrySelect onCountrySelect={handleCountrySelection} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default TopForm;