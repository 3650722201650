import React, { useEffect, useState } from 'react';
import axios from 'axios';
import domainConfig from "../config";
import '../css/Testimonies.css'; // Import the CSS file

const Testimonies = ({ country }) => {
  const [testimonies, setTestimonies] = useState([]);

  useEffect(() => {
    const fetchTestimonies = async () => {
      try {
        const response = await axios.get(`${domainConfig()}/testimonies/${country}`);
        setTestimonies(response.data);
      } catch (error) {
        console.error("Failed to fetch testimonies:", error);
      }
    };

    fetchTestimonies();
  }, [country]);

  return (
    <div className="testimonies-container">
      <h2 className="testimonies-title">Testimonies for used iPhones</h2>
      <ul className="testimonies-list">
        {testimonies.map((testimonial, index) => (
          <li key={index}>
            <span className="testimonial-name">{testimonial.name}</span> (<span className="testimonial-age">{testimonial.age}</span>, <span className="testimonial-occupation">{testimonial.occupation}</span>): 
            <p className="testimonial-text">{testimonial.testimony}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Testimonies;