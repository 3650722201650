import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Header from './component/Header';
import TopForm from './component/TopForm';
import Home from './component/Home';
import Model from './component/Model';
import Footer from './component/Footer';
import DropDash from './component/DropDash';
import CookieConsent from 'react-cookie-consent';
import CustomHelmet from './component/CustomHelmet';
import { getBrowserLanguage } from './component/i18n';


function App() {
    const { i18n } = useTranslation();
    const browserLanguage = getBrowserLanguage();

    useEffect(() => {
        i18n.changeLanguage(browserLanguage);
    }, [browserLanguage, i18n]);

    let titleKey = "title"; // Default key
    let descriptionKey = "content"; // Default key



    return (
        <div className='App'>
            <BrowserRouter>
            <CustomHelmet titleKey={titleKey} descriptionKey={descriptionKey} />
                <Header />
                <Routes>
                    <Route path="/" element={
                        <>
                            <TopForm />
                            <DropDash />
                        </>
                        } />
                    <Route exact path="country/:country" element={<Home />} />
                    <Route exact path="country/:country/:model" element={<Model />} />
                    <Route path='*' element={<h1 className="error">Page not found</h1>} />
                </Routes>
                <Footer />
            </BrowserRouter>
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="myAwesomeCookieName2"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
            />
        </div>
    );
}

export default App;
