import React, { useState, useEffect} from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../css/ProductCard.css';


const ProductCard = ({ product, selectedCountry, isLowest, langCode }) => {
   
    const country = useParams().country;
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); 

    const handleCardClick = () => {
        navigate(`/country/${country}/${product.model}`);
    };

    useEffect(() => {
        i18n.changeLanguage(langCode);
    }, [langCode]);

    const { seller, price, currency, model, product_title } = product;
    // Create badges from the product_title array
    const tags = product_title && Array.isArray(product_title) ? product_title.map((tag, index) => (
        <Badge bg="secondary" key={index}>{tag}</Badge>
    )) : null;

    return (
        <div className="unit snipcss-sp5Xl">
            <div className="body">
                <div className="col-left">
                    <a title={`Price History of ${country}`} className="link" onClick={handleCardClick} rel="nofollow">
                        <div className="model-thumbnail">
                            {model}
                        </div>
                    </a>
                </div>
                <div className="col-right">
                    <div className="title">
                        <b>
                            <a title={model} className="link" onClick={handleCardClick} rel="nofollow"> {model} </a>
                        </b>
                    </div>
                    <div className="price">
                        <span className="final">{currency} {price.toLocaleString()}</span>
                    </div>
                    <div className="cmo-toggler cmo-toggler-less">
                        <div className="tag-info">
                        {isLowest && <Badge bg="info" className='lowest-tag'>{t('greatDeal')}</Badge>}
                            {tags}
                        </div>
                    </div>
                    <div className="btn-wrap">
                        <a title="View Back Market Price History Chart" onClick={handleCardClick} role="button" rel="nofollow">{t('viewPriceHistory')}</a>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default ProductCard;